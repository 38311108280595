import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnrollID } from '../interfaces/enroll-id';
import { FirmaResp } from '../interfaces/firma-resp';
import { QrCode } from '../interfaces/qr-code';
import { StatusIDNow } from '../interfaces/status-idnow';
import { ApiServicesService } from './api-services.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class IdnowService {
  private apiURL = environment.url;
  private shortUrlApi = 'https://api.short.io/links/public';
  private domainTicnow = 'go.ticnow.cl';

  constructor(
    private apiService: ApiServicesService,
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {}

  getQR(userId: any): Observable<QrCode> {
    console.log('userId', userId);
    return this.httpClient
      .get<QrCode>(this.apiURL + '/transaction/ticnow?', {
        params: { userId: userId },
      })
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp);
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'getQR');
            }
          }
        })
      );
  }

  signAprove(item: any): Observable<FirmaResp> {
    console.log('item', item);
    return this.httpClient
      .post<FirmaResp>(
        this.apiURL + '/document/ticnow/sign',
        item,
        this.apiService.httpOptions
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp);
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'signAprove');
            }
          }
        })
      );
  }
  checkStatus(item: any): Observable<StatusIDNow> {
    console.log('item', item);
    return this.httpClient
      .post<StatusIDNow>(
        this.apiURL + '/transaction/ticnow',
        item,
        this.apiService.httpOptions
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp);
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'checkStatus');
            }
          }
        })
      );
  }

  shortUrl(url: any): Observable<StatusIDNow> {
    console.log('original url', url);
    let data = {originalURL: url, domain: this.domainTicnow};
    return this.httpClient
      .post<any>(
        this.shortUrlApi,
        data,
        this.apiService.httpOptionsShortUrl
      );
  }

}
