import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { AccessWebComponent } from './pages/access-web/access-web.component';
import { ContractReaderComponent } from './pages/contract-reader/contract-reader.component';
import { ReadContractComponent } from './pages/contract-reader/read-contract/read-contract.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { QrModalComponent } from './pages/contract-reader/qr-modal/qr-modal.component';
import { FinalScreenComponent } from './pages/final-screen/final-screen.component';
import { ErrorScreenComponent } from './pages/error-screen/error-screen.component';
import { SearchContractComponent } from './pages/search-contract/search-contract.component';
import { HttpClientModule } from '@angular/common/http';
import { SuccesSignComponent } from './pages/succes-sign/succes-sign.component';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'white',
  bgsOpacity: 0.1,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'rectangle-bounce',
  blur: 8,
  delay: 0,
  fgsColor: '#44c9ab',
  fgsPosition: 'center-center',
  fgsSize: 70,
  fgsType: 'rectangle-bounce',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.4)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};
@NgModule({
  declarations: [
    AppComponent,
    AccessWebComponent,
    ContractReaderComponent,
    ReadContractComponent,
    QrModalComponent,
    FinalScreenComponent,
    ErrorScreenComponent,
    SearchContractComponent,
    SuccesSignComponent,
    FormatTimePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    MatDialogModule,
    PdfViewerModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    NgxQRCodeModule
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
