import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, retry, throwError } from 'rxjs';
import { Documents } from '../interfaces/documents';
import { NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class ApiServicesService {
  private autorization = 'pk_e4DmciZ8Db2EViUi';

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsShortUrl = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': this.autorization
    }),
  };

  public errorNotification(error: any, service: any) {
    console.log('Servicio que responde con error: ' + service, error);
    this.notificationService.showError(
      'Error: ' +
        error.code +
        ' en el servicio ' +
        service +
        ', Por favor intente de nuevo en unos minutos',
      'Error'
    );
  }
}
