<div class="row centrado fullHeight">
  <div class="card setBackgroundImage">
    <div class="card-container">
      <form class="example-form" #confirmForm="ngForm">
        <div class="row centrado slide-in-top">
          <div class="row">
            <div class="col-md-12">
              <h4 class="colorRojo">
                Ha ocurrido un error en la validación de su identidad
              </h4>
              <hr />
            </div>
          </div>
          <div class="row padding10 centrado">
            <div class="col-md-3">
              <img
                src="../../../assets/images/314920_error_document_icon.png"
              />
              <p>
                <strong
                  >Por favor intenta realizar el procedimiento nuevamente, ya
                  que no pudimos validar tu identidad para firmar el
                  contrato</strong
                >
              </p>
            </div>
          </div>
          <hr />
        </div>
      </form>
    </div>
  </div>
</div>
