<div class="row centrado fullHeight">
  <div class="card setBackgroundImage">
    <div class="card-container">
      <form class="example-form" #confirmForm="ngForm">
        <div class="row centrado slide-in-top">
          <div class="row">
            <div class="col-md-12">
              <h4 class="colorPrincipal">
                Sus contratos han sido firmado exitosamente, en un enrolamiento exitoso
              </h4>
              <hr />
            </div>
          </div>
          <div class="row padding10 centrado">
            <div class="col-md-3">
              <img
                src="../../../assets/images/4177631_document_pen_sign_signature_write_icon.png"
              />
            </div>
          </div>
          <hr />
        </div>
      </form>
    </div>
  </div>
</div>
