<div class="row setBackground centrado fullHeight">
  <div class="card setBackgroundImage">
    <div class="card-container">
      <div class="row centrado slide-in-top">
        <form class="example-form" #companyForm="ngForm">
          <div class="col-md-12 padding50">
            <img src="assets/images/logo.png" class="imgLogoMini" />
          </div>
          <div class="row">
            <div class="col-md-12">
              <h4>Acceso Web</h4>
            </div>
            <div class="col-md-12">
              <p>
                Bienvenido a nuestro portal, por favor ingreselos datos
                necesarios a continuación para comenzar
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  placeholder="RUT"
                  (keypress)="utilService.restrictNumeric($event)"
                  (blur)="formatRUT($event)"
                  oninput="this.value = this.value.toUpperCase()"
                  [(ngModel)]="info.client_rut"
                  name="client_rut"
                  required
                />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field class="example-full-width">
                <input
                  matInput
                  required
                  placeholder="Cotizacion"
                  name="quotation_number"
                  [(ngModel)]="info.quotation_number"
                />
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-12 centrado padding50">
            <div class="col-md-3"></div>
            <div class="col-md-4 col-sm-6">
              <a
                class="nav-link botonID principalBackground padding10"
                (click)="onConfirmClick(companyForm)"
              >
                INICIAR
              </a>
            </div>
            <div class="col-md-3"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
