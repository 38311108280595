<div class="row centrado">
  <div >
    <img src="assets/images/logo.png" class="imgLogoParque" />
  </div>
  <ngx-ui-loader
    [loaderId]="'loader-02'"
    text="Por favor espera mientras procesamos tus documentos"
    fgsType="ball-spin-clockwise"
    overlayColor="rgba(40, 40, 40, 0.8)"
  ></ngx-ui-loader>
  <div class="card setBackgroundImage">
    <div class="card-container">
      <form class="example-form" #confirmForm="ngForm">
        <div
          class="row centrado"
          *ngIf="pendingDocuments && pendingDocuments.length !== 0"
        >
          <div class="row">
            <div class="col-md-12">
              <p class="">
                El cliente
                <strong
                  >{{ userData?.client_rut }}-{{
                    userData?.client_dv_rut
                  }}</strong
                >, asociado a la cotización
                <strong>{{ userData?.quotation_number }}</strong> posee:
              </p>
              <h5 class="colorPrincipal">
                {{ userData?.lstDocuments.length }} Documentos pendientes por
                firmar
              </h5>
              <hr />
            </div>
          </div>
          <div class="row padding10">
            <div class="col-md-3" *ngFor="let paper of pendingDocuments">
              <a (click)="openModal(paper)">
                <img
                  src="../../../assets/images/728928_document_paper_data_file_page_icon.png"
                  alt="{{ paper?.document_name }}"
                  title="{{ paper?.document_name }}"
                />
                <p><strong>Documento</strong>: {{ paper?.document_name }}</p>
              </a>
            </div>
          </div>
          <hr />

          <div class="row centrado padding10">
            <div class="col-md-12">
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="info.read"
                name="read"
                >He leído cada uno de los contratos</mat-checkbox
              >
            </div>
            <div class="col-md-12">
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="info.accept"
                name="accept"
                >Acepto los términos y condiciones</mat-checkbox
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <a
                class="nav-link botonID principalBackground padding10"
                (click)="onConfirmClick(confirmForm)"
              >
                Firmar
              </a>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
