import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { ReadContractComponent } from './read-contract/read-contract.component';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { QrModalComponent } from './qr-modal/qr-modal.component';
import { DocumentService } from 'src/app/services/document.service';
import { IdnowService } from 'src/app/services/idnow.service';
import { Documents } from 'src/app/interfaces/documents';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/internal/operators/take';

@Component({
  selector: 'app-contract-reader',
  templateUrl: './contract-reader.component.html',
  styleUrls: ['./contract-reader.component.css'],
})
export class ContractReaderComponent implements OnInit {
  contractsInfo: IContracts[] = [];
  formBuilder: any;

  data = {
    client_rut: '',
    quotation_number: '',
  };
  info = {
    accept: false,
    read: false,
    base: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALoAAAC6AQMAAAAjwMWyAAAABlBMVEX///8AAABVwtN+AAABRElEQVRYhc2X0Q3DMAhEkTxARvLqHskDIFHuLoladYCz1TaNXz4QcBccceQahRVXRcy8qvbE/XaD/sYctfTE2DO0aQazQ+zdZMR8qA4BuO/fax0GogjyFBDMXVQtttpvzV3g6bP386MPE3hXh0uJ/i0LuCiAbrWrk7iU07SD6IbrVKLVYGSsLrET9BaVyRpDCR39QrhecPfWhAzgaNh99WEDTJ9eRfhPLys72Lyg56BJFXjYQZX6PmUfbL6yg02ziLuoDH1FmEFf6KoIN+kaj3E4gd7Wg/d8KFImawVB80qw0jDBnJrBHShLWxAn9JB2IGPlkApZ0vbtQGtsTc9rvOK0gvuYQf+isWLKUXadQMcMTs9JGUiobjA1NMs4nuiPANQAxEmVrjwEqPU03DxThhWEjmHSpMTwVXMTeGWgQLlrB0euD3OlZ7AupfLIAAAAAElFTkSuQmCC',
  };
  uuidQR: any;
  rut: any;
  numberCot: any;
  pendingDocuments: Documents[] = [];
  userData: any;
  action: any;
  disableButton: boolean = true; 
  uuidTx: any;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private idNowService: IdnowService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit() {
    this.action = this.activatedRoute.snapshot.queryParams['action'];
    this.rut = this.activatedRoute.snapshot.paramMap.get('client_rut');    
    this.numberCot =
    this.activatedRoute.snapshot.paramMap.get('quotation_number');
    if(this.activatedRoute.snapshot.queryParams['uuidTx'] !== null) {
      localStorage.setItem('uuidTx', this.activatedRoute.snapshot.queryParams['uuidTx']);
    }
    localStorage.setItem('rut', this.rut);
    localStorage.setItem('numberCot', this.numberCot);
    const item = {
      client_rut: this.rut,
      quotation_number: this.numberCot,
    };
    this.ngxLoader.start();
    this.documentService.getDocuments(item).subscribe((resp: any) => {
      if (resp.data.id) {
        this.userData = resp.data;
        this.userData.lstDocuments.forEach((element: any) => {
          if ((element.status === 'pending')) {
            this.pendingDocuments.push(element);
          }
        });
        if(this.pendingDocuments.length === 0) {
          this.router.navigate(['searchContract/', this.rut, this.numberCot]);
        }
        this.ngxLoader.stop();
        if(this.action === '1'){
          this.queryStatusEnrollment();
          this.disableButton = false;
        }
      } else {
        this.ngxLoader.stop();
        this.notificationService.showError(
          'Ocurrió un error solicitando sus documentos, por favor reintente su solicitud',
          'Error'
        );
        this.router.navigate(['accesWeb']);
      }
      //   console.log('esto retorna el servicio', resp);
    });
  }

  openModal(info: any): void {
    const dialogRef = this.dialog.open(ReadContractComponent, {
      width: '90%',
      data: info,
      hasBackdrop: true,
    });
  }

  openModalPDF(info: any) {
    const dialogRef = this.dialog.open(QrModalComponent, {
      width: '40%',
      data: info,
      hasBackdrop: true,
      disableClose: true,
    });
  }

  checkServicesIdNOW(uuid: any, rut: any, cot: any) {
    const item = {
      uuidTransaction: uuid,
      userId: rut,
    };
    let dataService = {
      rut: '',
      quotation_number: '',
      seed: '',
      uuidDevice: '',
    };
    this.ngxLoader.start();
    this.idNowService.checkStatus(item).subscribe((resp: any) => {
      if (resp) {
        console.log('respuesta idnow', resp);
        this.ngxLoader.stop();
        //En caso de aprobado
        dataService = {
          rut: rut,
          quotation_number: cot,
          seed: resp.data.seed,
          uuidDevice: resp.data.uuidDevice,
        };
        if (resp.data.status == 'R') {
          this.ngxLoader.startLoader('loader-02');
          //servicio para que los documentos se firmen y luego si ese servicio responde bien al final
          this.idNowService
            .signAprove(dataService)
            .subscribe((respSign: any) => {
              console.log('signAprove', resp);
              this.ngxLoader.stopLoader('loader-02');
              if (respSign?.data === 'OK') {
                // Si es correcto
                this.router.navigate(['final']);
              } else {
                // Error en la firma lo saca del flujo
                this.router.navigate(['error']);
              }
            });
        }
        //En caso de rechazado, cancelado, timeout y se redirecciona a firma ya
        if (
          resp.data.status == 'RE' ||
          resp.data.status == 'C' ||
          resp.data.status == 'TO'
        ) {
          this.notificationService.showInfo(
            'No pudimos validar tu identidad, serás redirigido en breve a una segunda validación',
            'Redirigiendo...'
          );
          setTimeout(() => {
            //ir a firma ya
            window.location.href = resp.tag;
          }, 5000);
        }
      } else {
        this.ngxLoader.stop();
      }
    });
  }

  onConfirmClick(form: any) {
    if(this.disableButton){
    if (this.info.accept && this.info.read) {
      this.ngxLoader.start();
      this.idNowService.getQR(this.rut).subscribe((resp: any) => {
        this.uuidQR = resp.data.uuidTransaction;
        localStorage.setItem('uuidTx', resp.data.uuidTransaction);
        console.log('this.uuidQR', this.uuidQR);
        this.ngxLoader.stop();
        console.log('abrír el modal');
        resp.data.url = resp.data.url + '&rut=' + localStorage.getItem('rut') + '&numberCot=' + localStorage.getItem('numberCot');        
        this.openModalPDF(resp);        
      });

      //TODO Aqui va el servicio que llama la API
    } else {
      this.ngxLoader.stop();
      this.notificationService.showError(
        'Por favor acepta las condiciones para continuar',
        'Error'
      );
    }
  }
  }

  queryStatusEnrollment() {
    let uuid = localStorage.getItem('uuidTx');
    this.info.read = true;
    this.info.accept = true;
    if(uuid !== undefined && uuid !== null && uuid !== '') {
      this.checkServicesIdNOW(
        uuid,
        this.rut,
        this.numberCot
      );
    }  else {
      this.disableButton = true;
      this.notificationService.showError(
        'Ocurrió un error en su solicitud, reintente más tardes',
        'Error'
      );
    }
  }

}
interface IContracts {
  title: string;
  url: string;
}
