import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirmaResp } from '../interfaces/firma-resp';
import { QrCode } from '../interfaces/qr-code';
import { ApiServicesService } from './api-services.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class FirmaYaService {
  private apiURL = environment.url;

  constructor(
    private apiService: ApiServicesService,
    private httpClient: HttpClient,
    private notificationService: NotificationService
  ) {}

  checkFirma(userId: any, localinfo: any): Observable<FirmaResp> {
    console.log('user_id: ', userId, 'localinfo: ', localinfo);
    return this.httpClient
      .get<FirmaResp>(this.apiURL + '/transaction/firmaya?', {
        params: {
          user_id: userId,
          rut: localinfo.client_rut,
          quotation: localinfo.quotation_number,
          uuidTx: localinfo.uuidTx,
          uuidDevice: localinfo.uuidDevice
        },
      })
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp);
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'checkFirma');
            }
          }
        })
      );
  }
  //cambiar interfaz y endopoint
  signedFirma(item: any): Observable<FirmaResp> {
    console.log('item', item);
    return this.httpClient
      .post<FirmaResp>(
        this.apiURL + '/document/ticnow/sign',
        item,
        this.apiService.httpOptions
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp);
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'signedFirma');
            }
          }
        })
      );
  }
}
