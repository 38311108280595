import { Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private notificationService: NotificationService) {}

  public restrictNumeric(event: any) {
    let k;
    k = event.charCode;
    return (k > 96 && k < 123) || k === 8 || k === 75 || (k >= 48 && k <= 57);
  }

  formatearEventRut(focusEvent: any): {
    rut: string;
    dv: string;
    error: string;
  } {
    return this.formatearRut(focusEvent.target.value);
  }

  formatearRutString(_rut: number, _dv: string): string {
    return this.formatearRut(_rut + _dv).rut;
  }

  formatearRut(_rut: string): { rut: string; dv: string; error: string } {
    const rut = _rut.replace(/\./g, '').replace(/-/g, ''); // Elimina '.' y '-' del rut.
    const result: any = { error: null };
    let nPos = 0; // Guarda el rut invertido con los puntos y el guión agregado
    let sInvertido = ''; // Guarda el resultado final del rut como debe ser
    let sRut = '';

    for (let i = rut.length - 1; i >= 0; i--) {
      sInvertido += rut.charAt(i);
      if (i === rut.length - 1) {
        sInvertido += '-';
      } else if (nPos === 3) {
        sInvertido += '.';
        nPos = 0;
      }
      nPos++;
    }
    for (let j = sInvertido.length - 1; j >= 0; j--) {
      if (sInvertido.charAt(sInvertido.length - 1) !== '.') {
        sRut += sInvertido.charAt(j);
      } else if (j !== sInvertido.length - 1) {
        sRut += sInvertido.charAt(j);
      }
    }

    const rutsinDV = rut.slice(0, -1);

    let DVReal = this.calcDV(rutsinDV).toLocaleString();
    let ultimoCaracter = sRut.charAt(sRut.length - 1).toLocaleString();
    if (
      ultimoCaracter === 'k' ||
      DVReal === 'k' ||
      ultimoCaracter === 'K' ||
      DVReal === 'K'
    ) {
      DVReal = this.calcDV(rutsinDV).toLocaleString().toUpperCase();
      ultimoCaracter = sRut
        .charAt(sRut.length - 1)
        .toLocaleString()
        .toUpperCase();
    }

    result.rut = sRut;
    result.dv = ultimoCaracter;

    if (rut.length !== 9 && rut.length !== 8) {
      result.error = 'Verifique la longitud del RUT';
      this.notificationService.showError(result.error, 'Error');
      // this.showNotification(result.error, 'danger');
    } else if (DVReal !== ultimoCaracter) {
      result.error = 'El RUT ingresado no es válido';
      this.notificationService.showError(result.error, 'Error');
      // this.showNotification(result.error, 'danger');
    }

    return result;
  }

  calcDV(T: any) {
    let M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) {
      S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    }
    return S ? S - 1 : 'k';
  }

 
}
