import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiServicesService } from './api-services.service';
import { Documents } from '../interfaces/documents';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private apiURL = environment.url;

  constructor(
    private apiService: ApiServicesService,
    private httpClient: HttpClient
  ) {}

  getDocuments(data: any): Observable<Documents> {
  return this.httpClient
      .post<Documents>(
        this.apiURL + '/transaction/document',
        data,
        this.apiService.httpOptions
      )
      .pipe(
        map((resp: any) => {
          if (resp) {
            if (!resp.error) {
              console.log(resp)
              return resp;
            } else {
              this.apiService.errorNotification(resp, 'getDocuments');
            }
          }
        })
      );
  }
  getEnvVar() {
    const client_rut = localStorage.getItem('client_rut');
    const quotation_number = localStorage.getItem('quotation_number');
    return {
      client_rut: client_rut,
      quotation_number: quotation_number,
    };
  }
  setEnvVar(data: any) {
    localStorage.setItem('client_rut', data.client_rut);
    localStorage.setItem('quotation_number', data.quotation_number);
  }

  cleanEnvVar(){
    localStorage.clear;
  }

}
