import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { IdnowService } from 'src/app/services/idnow.service';

@Component({
  selector: 'app-qr-modal',
  templateUrl: './qr-modal.component.html',
  styleUrls: ['./qr-modal.component.css'],
})
export class QrModalComponent implements OnInit {
  constructor(
    public domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<QrModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private idNowService: IdnowService
  ) {}

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  showQr: boolean = false;

  ngOnInit() {

    this.idNowService.shortUrl(this.data.data.url).subscribe((url: any) => {
      this.value = url.shortURL;
      this.showQr = true;
    });
   }

  close() {
    this.dialogRef.close();
    this.router.navigate(['accesWeb']);
  }
}
