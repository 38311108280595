<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">X</mat-icon>
</button>
<h4 mat-dialog-title>{{ data.document_name }}</h4>

<div mat-dialog-content>
  <pdf-viewer
    src="data:application/pdf;base64, {{ data.document }}"
    [original-size]="false"
    [show-all]="true"
    [fit-to-page]="false"
    [stick-to-page]="false"
    [render-text]="true"
    [autoresize]="true"
    [show-borders]="false"
    style="width: 100%; height: 600px"
  ></pdf-viewer>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    class="nav-link botonID principalBackground padding10"
    mat-dialog-close
  >
    Cerrar
  </button>
</div>
