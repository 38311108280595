import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Documents } from 'src/app/interfaces/documents';
import { DocumentService } from 'src/app/services/document.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ReadContractComponent } from '../contract-reader/read-contract/read-contract.component';

@Component({
  selector: 'app-search-contract',
  templateUrl: './search-contract.component.html',
  styleUrls: ['./search-contract.component.css'],
})
export class SearchContractComponent implements OnInit {
  formBuilder: any;

  data = {
    client_rut: '',
    quotation_number: '',
  };

  completeDocuments: Documents[] = [];
  userData: any;
  rut: any;
  numberCot: any;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.rut = this.activatedRoute.snapshot.paramMap.get('client_rut');
    this.numberCot =
      this.activatedRoute.snapshot.paramMap.get('quotation_number');

    localStorage.setItem('rut', this.rut);
    localStorage.setItem('numberCot', this.numberCot);
    const item = {
      client_rut: this.rut,
      quotation_number: this.numberCot,
    };
    this.ngxLoader.start();
    this.documentService.getDocuments(item).subscribe((resp: any) => {
      if (resp.data.id) {
        this.userData = resp.data;
        this.userData.lstDocuments.forEach((element: any) => {
          if (element.status == 'signed') {
            this.completeDocuments.push(element);
          }

       //   console.log('pendientes', this.completeDocuments.length);
        });
        if (this.completeDocuments.length === 0) {
          this.notificationService.showError(
            'No posee documentos firmados disponibles para revisión',
            'Error'
          );
          this.router.navigate(['accesWeb']);
        }
        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
        this.notificationService.showError(
          'Ocurrió un error solicitando sus documentos firmados, por favor reintente su solicitud',
          'Error'
        );
        this.router.navigate(['accesWeb']);
      }
     // console.log('esto retorna el servicio', resp);
    });
  }

  openModal(info: any): void {
    const dialogRef = this.dialog.open(ReadContractComponent, {
      width: '90%',
      data: info,
      hasBackdrop: true,
    });
  }
}
