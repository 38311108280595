import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DocumentService } from 'src/app/services/document.service';
import { FirmaYaService } from 'src/app/services/firma-ya.service';
import { IdnowService } from 'src/app/services/idnow.service';

@Component({
  selector: 'app-succes-sign',
  templateUrl: './succes-sign.component.html',
  styleUrls: ['./succes-sign.component.css'],
})
export class SuccesSignComponent implements OnInit {
  user_id: any;
  message = 'Estamos procesando tu firma';
  localInfo: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private firmayaService: FirmaYaService,
    private router: Router,
    private documentService: DocumentService,
    private idNowService: IdnowService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.user_id = params['user_id'];
    });
    this.localInfo = this.documentService.getEnvVar();
    this.checkSign(this.user_id);
  }

  checkSign(id: any) {
    let rut = localStorage.getItem('rut');
    let cot = localStorage.getItem('numberCot');
    let uuidTx = localStorage.getItem('uuidTx');
    const item = {
      uuidTransaction: uuidTx,
      userId: rut,
    };
    this.ngxLoader.start();
    this.idNowService.checkStatus(item).subscribe((resp: any) => {
      if (resp) {
        console.log('respuesta idnow', resp);
        this.ngxLoader.stop();
        this.ngxLoader.startLoader('loader-02');
        this.localInfo = {
          client_rut: rut,
          quotation_number: cot,
          uuidTx: resp.data.seed,
          uuidDevice: resp.data.uuidDevice
        }
          //servicio para que los documentos se firmen y luego si ese servicio responde bien al final
          this.firmayaService.checkFirma(id, this.localInfo).subscribe((respSign) => {
            this.ngxLoader.stop();
            console.log('resp', respSign);
            if (respSign?.data === 'OK') {
              // Si es correcto
              this.message = respSign.tag;
            } else {
              // Error en la firma lo saca del flujo
              this.router.navigate(['error']);
            }
          });
      } else {
        this.ngxLoader.stop();
      }
    });   
  }

  signDoc() {
    let rut = localStorage.getItem('rut');
    let cot = localStorage.getItem('numberCot');
    const item = {
      uuidTransaction: localStorage.getItem('uuidTx'),
      userId: rut,
    };
    this.ngxLoader.start();
    this.idNowService.checkStatus(item).subscribe((resp: any) => {
      if (resp) {
        console.log('respuesta idnow', resp);
        this.ngxLoader.stop();
        //En caso de aprobado
        let dataService = {
          rut: rut,
          quotation_number: cot,
          seed: resp.data.seed,
          uuidDevice: resp.data.uuidDevice,
        };
          this.ngxLoader.startLoader('loader-02');
          //servicio para que los documentos se firmen y luego si ese servicio responde bien al final
          this.idNowService
            .signAprove(dataService)
            .subscribe((respSign: any) => {
              console.log('signAprove', resp);
              this.ngxLoader.stopLoader('loader-02');
              if (respSign?.data === 'OK') {
                // Si es correcto
                this.router.navigate(['final']);
              } else {
                // Error en la firma lo saca del flujo
                this.router.navigate(['error']);
              }
            });
      } else {
        this.ngxLoader.stop();
      }
    });
  }

}
