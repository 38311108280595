<div class="row centrado">
  <div >
    <img src="assets/images/logo.png" class="imgLogoParque" />
  </div>
  <div class="card setBackgroundImage">
    <div class="card-container">
      <form class="example-form" #confirmForm="ngForm">
        <div
          class="row centrado slide-in-top"
          *ngIf="completeDocuments && completeDocuments.length !== 0"
        >
          <div class="row">
            <div class="col-md-12">
              <p class="">
                El cliente
                <strong
                  >{{ userData?.client_rut }}-{{
                    userData?.client_dv_rut
                  }}</strong
                >, asociado a la cotización
                <strong>{{ userData?.quotation_number }}</strong> posee:
              </p>
              <h5 class="colorPrincipal">
                {{ userData?.lstDocuments.length }} Documentos
                firmados
              </h5>
              <hr />
            </div>
          </div>
          <div class="row padding10">
            <div class="col-md-3" *ngFor="let paper of completeDocuments">
              <a (click)="openModal(paper)">
                <img
                  src="../../../assets/images/728928_document_paper_data_file_page_icon.png"
                  alt="{{ paper?.document_name }}"
                  title="{{ paper?.document_name }}"
                />
                <p><strong>Documento</strong>: {{ paper?.document_name }}</p>
              </a>
            </div>
          </div>
          <hr />



        </div>
      </form>
    </div>
  </div>
</div>
