<h4 mat-dialog-title>Escanea el código QR</h4>
<div mat-dialog-content>
  <div class="row centrado">
    {{ data.qrCode }}
    <ngx-qrcode
  [elementType]="elementType"
  [errorCorrectionLevel]="correctionLevel"
  [value]="value"
  alt="Demo QR Code"
  cssClass="bshadow" *ngIf="showQr"></ngx-qrcode>
    <a class="text-link" href="{{ data.data.url }}">o ingresa via web por medio de este Link</a
    >
  </div>  
  <div>
    
  </div>
</div>
<div mat-dialog-actions class="centrado">
  <a class="nav-link botonID principalBackground padding10"
     (click)="close()"> Volver al Inicio </a>
  </div>
