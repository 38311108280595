import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessWebComponent } from './pages/access-web/access-web.component';
import { ContractReaderComponent } from './pages/contract-reader/contract-reader.component';
import { ErrorScreenComponent } from './pages/error-screen/error-screen.component';
import { FinalScreenComponent } from './pages/final-screen/final-screen.component';
import { SearchContractComponent } from './pages/search-contract/search-contract.component';
import { AlwaysAuthGuardService } from 'src/app/services/always-auth-guard.service';
import { SuccesSignComponent } from './pages/succes-sign/succes-sign.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'accesWeb',
    pathMatch: 'full',
  },
  {
    path: 'accesWeb',
    component: AccessWebComponent,
    pathMatch: 'full',
  },
  {
    path: 'searchContract/:client_rut/:quotation_number',
    component: SearchContractComponent,
    pathMatch: 'full',
  },
  {
    path: 'success-sign',
    component: SuccesSignComponent,
    pathMatch: 'full',
  },
  {
    path: 'contract/:client_rut/:quotation_number',
    component: ContractReaderComponent,
    pathMatch: 'full',
  },
  {
    path: 'final',
    component: FinalScreenComponent,
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorScreenComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
