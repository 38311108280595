import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-read-contract',
  templateUrl: './read-contract.component.html',
  styleUrls: ['./read-contract.component.css'],
})
export class ReadContractComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ReadContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    //    console.log('data', this.data);
  }
  logError(error: any) {
    console.error(error);
  }
}
