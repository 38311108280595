import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UtilService } from 'src/app/helpers/util.service';
import { DocumentService } from 'src/app/services/document.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-access-web',
  templateUrl: './access-web.component.html',
  styleUrls: ['./access-web.component.css'],
})
export class AccessWebComponent implements OnInit {
  endingButton = false;
  info = {
    client_rut: '',
    quotation_number: '',
  };
  notValid = false;

  constructor(
    public utilService: UtilService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private documentService: DocumentService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.initBlankFields();
  }
  private initBlankFields() {}
  formatRUT(focusEvent: any) {
    this.notValid = false;
    this.checkButton();
    const resp = this.utilService.formatearEventRut(focusEvent);
    this.info.client_rut = resp.rut;

    if (resp.error) {
      this.notValid = true;
    }
  }
  checkButton() {
    this.endingButton = false;
  }
  onConfirmClick(form: any) {
    let pendingDocuments: any[] = [];
    let data = {
      client_rut: '',
      quotation_number: '',
    };
    this.ngxLoader.start();
    this.endingButton = true;
    if (form.valid && !this.notValid) {
      data = form.value;
      data.client_rut = data.client_rut.replace(/\./g, '');
      this.endingButton = true;
      this.documentService.getDocuments(data).subscribe((resp: any) => {
        // console.log('RESP: ', resp);
        // console.log('RESP id: ', resp.data.id);
        if (resp.data.id) {
          //setea las variables de entorno
          console.log('va a guardar las variables de entorno');
          this.documentService.setEnvVar(data);
          this.ngxLoader.stop();
          if (resp.data.status === 'signed') {
            //En caso de que no tenga documentos por firmar, todos completados
            this.router.navigate([
              'searchContract' +
                '/' +
                data.client_rut +
                '/' +
                data.quotation_number,
            ]);
          } else {
            this.router.navigate([
              //En caso de que tenga documentos pendientes
              'contract' + '/' + data.client_rut + '/' + data.quotation_number,
            ]);
          }
        } else {
          // en caso de que no exista un rut + cotización asociado
          this.ngxLoader.stop();
          this.notificationService.showError(
            'No se encuentran documentos asociados al rut: ' +
              data.client_rut +
              ' y cotización: ' +
              data.quotation_number +
              ', por favor intente en otro momento',
            'Error'
          );
        }
      }),
        (err: any) => {
          console.log('error en document service', err);
        };
    } else {
      this.notificationService.showError(
        'Por favor complete el formulario correctamente',
        'Error'
      );
    }
  }
}
